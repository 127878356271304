<template>
  <div>
    <NavTitle class="mb16" :title="showBack ? '返回' : '商户投诉'" :showBack="showBack" @back="handleBack">
      <template #right>
        <div class="flex items-center">
          <el-select class="mr10" v-model="queryType" placeholder="请选择查询类型">
            <el-option
              v-for="item in queryTypes"
              :key="item.type"
              :label="item.text"
              :value="item.type">
            </el-option>
          </el-select>
          <div class="input-row">
            <el-input class="input" placeholder="请输入ID/手机号" clearable v-model="seachWord" @change="handleSearch"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"  @click="handleSearch"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="ml20 mr20">
      <el-menu  :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index" style="position:relative;">
          {{item.name}}
          <!-- <span v-if="item.type == 1 && totalNum && Number(totalNum.dealingNum) != 0" class="msg-count">{{totalNum.dealingNum > 99 ? '99+' : totalNum.dealingNum}}</span>
          <span v-if="item.type == 2 && totalNum && Number(totalNum.overDueNum) != 0" class="msg-count">{{totalNum.overDueNum > 99 ? '99+' : totalNum.overDueNum}}</span>
          <span v-if="item.type == 3 && Number(totalNum.apprisedNum)" class="msg-count">{{totalNum.apprisedNum}}</span> -->
        </el-menu-item>
      </el-menu>
    </div>
    <div class="panel">
      <el-row :gutter="20" v-if="reportList && reportList.length > 0">
        <el-col :span="12" class="mt16" v-for="(item, index) in reportList" :key="index">
          <div class="report-item">
            <div class="title-row">
              <div class="flex items-center">
                <LoadImage class="avatar" :src="item.reporterLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
                <span class="bold">{{item.reporterName}}</span>
              </div>
              <span class="time">{{item.gmtCreate}}</span>
            </div>
            <div class="flex between-center">
              <div>
                <div class="flex items-center">
                  <span>{{item.name}}</span>
                  <div class="enter-arrow" @click="$refs.postDetailRef.open({pid: item.pid})">
                    <span>报名职位：{{item.postTitle}}</span>
                    <img src="@/assets/images/arrow-right-theme.png">
                  </div>
                </div>
                <div class="info-row">
                  <span>{{item.gender}}</span>
                  <div class="gap-line"></div>
                  <span>{{item.age}}</span>
                  <div class="gap-line"></div>
                  <span>{{item.profType}}</span>
                </div>
              </div>
              <div class="img-list">
                <LoadImage class="img" v-for="(url, index) in item.evidence" :key="index" :src="url" preview></LoadImage>
              </div>
            </div>
            <div class="flex between-end mt10">
              <div>
                <div class="mb8">投诉原因：{{item.reason}}</div>
                <div class="mb8" v-if="item.ctype">赔付方式：{{item.ctype}}</div>
                <div class="mb8" v-for="(correct, i) in item.correctInfos" :key="i">{{correct}}</div>
                <template v-if="listType == 3 && item.auditResult">
                  <div class="mb8">驳回原因：{{item.auditResult.auditReason}}</div>
                  <div class="mb8">驳回时间：{{item.auditResult.auditTime}}</div>
                </template>
                
              </div>
              <div class="flex items-center" v-if="listType == 1">
                <el-button type="primary" @click="handlePass(item.rid)">投诉成立</el-button>
                <el-button type="danger" @click="$refs.refusePopupRef.open({rid: item.rid})">投诉不成立</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="reportList.length > 0">
        <!-- <el-pagination
          v-if="totalPage"
          layout="prev, pager, next"
          :total="totalPage" :current-page="page.current + 1" :page-size="page.size" @current-change="handlePageChange">
        </el-pagination> -->
        <Pagination :current-page="page.current + 1" @current-change="handlePageChange"/>
      </div>
    </div>
    <!-- 职位详情 -->
    <PostDetailPopup ref="postDetailRef"></PostDetailPopup>
    <!-- 驳回弹窗 -->
    <RefusePopup ref="refusePopupRef" @update="getReportList"></RefusePopup>
  </div>
  
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import LoadImage from '@/components/LoadImage'
import PostDetailPopup from '@/components/PostDetailPopup'
import RefusePopup from './components/RefusePopup'
import { reportedStatics } from '@/api/report.js'
import { reportResumeListApi, auditReportResumeApi } from '@/api/admin.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'MerchantReportList',
  components: {
    NavTitle,
    Pagination,
    LoadImage,
    PostDetailPopup,
    RefusePopup
  },
  data() {
    return {
      menuList: [
        {name: '新投诉', type: '1'},
        {name: '审核通过', type: '2'},
        {name: '审核驳回', type: '3'},
      ],
      queryTypes: [
        {text: '根据商户信息查询', type: 1},
        {text: '根据用户信息查询', type: 2}
      ],
      reportList: [],
      page: {
        current: 0,
        size: 10
      },
      listType: '1',
      totalNum: null,
      seachWord: '',
      queryType: 1
    }
  },
  computed: {
    totalPage() {
      if(!this.totalNum) return 0
      if(this.listType == 1) return this.totalNum.dealingNum || 0
      if(this.listType == 2) return this.totalNum.overDueNum || 0
      if(this.listType == 4) return this.totalNum.completedNum || 0
      if(this.listType == 5) return this.totalNum.revokedNum || 0
    },
    showBack() {
      const { mid } = this.$route.query
      return mid ? true : false
    }
  },
  created() {
    this.getReportList()
    // this.getTotalNum()
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBack()
    next()
  },
  activated() {
    console.log('this.$route.query', this.$route.query)
    const { mid } = this.$route.query
    if(mid) {
      this.seachWord = mid
      // this.listType = '1'
      this.getReportList()
      // this.getTotalNum()
    }
  },
  methods: {
    getReportList() {
      const formData = {
        type: Number(this.listType),
        startPage: this.page.current
      }
      if(this.queryType == 1) {
        Object.assign(formData, {
          uidKey: this.seachWord.trim()
        })
      }
      if(this.queryType == 2) {
        Object.assign(formData, {
          brUidKey: this.seachWord.trim()
        })
      }
      console.log('formdata', formData)
      reportResumeListApi(formData).then(res => {
        console.log('商家投诉列表',res)
        const list = res.data.reportResumeLists || []
        if(list.length != 0 || this.page.current == 0) {
          list.forEach(item => {
            if(item.reporterLogo?.startsWith('oss')) {
              ossKeyToUrl({key: item.reporterLogo}).then(ossRes => {
                item.reporterLogo = ossRes.url
              })
            }
            if(item.evidence?.length > 0) {
              item.evidence.forEach((ev, index) => {
                ossKeyToUrl({key: ev}).then(ossRes => {
                  this.$set(item.evidence, index, ossRes.url)
                })
              })
            }
          })
          this.reportList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    getTotalNum() {
      reportedStatics({
        searchKey: this.seachWord.trim()
      }).then(res => {
        console.log('统计数据', res)
        this.totalNum = res.data
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    menuSelect(val) {
      this.listType = val
      this.page.current = 0
      this.getReportList()
    },
    toDetail(item) {
      console.log(item)
      this.$router.push({
        path: '/report/detail',
        query: {
          pid: item.pid,
          rid: item.rid
        }
      })
    },
    handleSearch() {
      this.page.current = 0
      this.getReportList()
      // this.getTotalNum()
    },
    handlePageChange(e) {
      console.log(e)
      this.page.current = e.page - 1
      this.getReportList()
    },
    handleBack() {
      this.seachWord = ''
      this.getReportList()
      // this.getTotalNum()
    },
    handlePass(rid) {
      this.$confirm('确定投诉成立吗？').then(action => {
        if(action == 'confirm') {
          const formData = {
            act: 1,
            rid
          }
          console.log('formData', formData)
          auditReportResumeApi(formData).then(res => {
            this.$tips({message: res.msg, type:'success'})
            this.getReportList()
          }).catch(err => {
            this.$tips({message: err, type:'error'})
          })
        }
      }).catch(err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
.panel {
  // background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin: 0 20px;
}

.report-item {
  background-color: #fff;
  padding: 24px;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F5F5F5;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 8px;
    }
    .time {
      color: #666;
    }
  }
  .enter-arrow {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: var(--theme-color);
    cursor: pointer;
    margin-left: 10px;
    img {
      width: 13px;
      height: 13px;
    }
  }
  .info-row {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 16px;
    margin-top: 12px;
  }
  .img-list {
    display: flex;
    .img {
      width: 56px;
      height: 56px;
      border-radius: .06rem;
      margin-left: 10px;
    }
  }
  .reason {
    color: var(--theme-color-red);
    margin-top: 12px;
  }
}

.msg-count {
  position: absolute;
  right: 0;
  top: 20%;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #FF5F58;
  color: #fff;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 16px;
  padding: 0 4px;
}
</style>